import React, { useContext} from 'react'
import "./Navbar.scss"

import { Link } from 'react-router-dom'
import logo_circle from '../../assets/logos/upcircle_logo_circle.png'
import logout_icon from '../../assets/icons/white/logout.png'
import { signOut } from 'firebase/auth'
import { auth } from '../../context/firebase'
import { AuthContext } from '../../context/AuthContext'

// NOTE: in currentUser.<attributes>, the attributes come from the firestore authentication (NOT the cloud firestore).  

const Navbar = () => {
    const {currentUser} = useContext(AuthContext);
    return (
        <div className='navbar'>
            <Link to="/">
                <img src={ logo_circle } className="logo" alt="logo" />
            </Link>
            <div className="user">
                <span>Hi, {currentUser.displayName}</span>
                <img src={currentUser.photoURL} alt="" className='user_photo_img'/>
                <button onClick={() => signOut(auth)} style={{display:"none"}} id='logout_icon'/>
                <label htmlFor='logout_icon'>
                    <img src={logout_icon} alt="logout icon" className='logout_icon_img'/>
                </label>
            </div>
        </div>
    )
}

export default Navbar