import React from 'react';
import './MultiSelectDropdown.scss'

export default function MultiSelectDropdown({ selectedOptions, options, onChange }) {
    const [isOpen, setIsOpen] = React.useState(false);
    const dropdownRef = React.useRef(null);
  
    const toggleDropdown = () => setIsOpen(!isOpen);
  
    const handleOptionChange = (option) => {
      const currentIndex = selectedOptions.indexOf(option);
      const newSelectedOptions = [...selectedOptions];
  
      if (currentIndex === -1) {
        newSelectedOptions.push(option);
      } else {
        newSelectedOptions.splice(currentIndex, 1);
      }
  
      onChange(newSelectedOptions); // Propagate changes up to the parent component
    };

  // Effect for handling clicks outside the dropdown
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown if the click is outside
      }
    };

    // Add event listener when the component is mounted
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const buttonLabel = Object.keys(selectedOptions).length === 0 ? "keine" : `${Object.keys(selectedOptions).length} ausgewählt`
  
  return (
    <div className="multi-select-dropdown" ref={dropdownRef}>
      <button onClick={toggleDropdown} className="dropbtn">{buttonLabel}</button>
      {isOpen && (
        <ul className="dropdown-content">
          {Object.keys(options).map((labelId, index) => (
            <li key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(labelId)}
                  onChange={() => handleOptionChange(labelId)}
                />
                {options[labelId]}
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}