import React from 'react';
import Logo from '../assets/logos/upcircle_logo_circle.png';

const LoadingScreen = () => {
  return (
    <div className='loading-screen'>
      <div className='spinning-logo-container'>
        <img src={Logo} className="spinning-logo" />
      </div>
      <p>Loading ...</p>
    </div>
  )
}

export default LoadingScreen