// This file includes firebase resources and API, 
// exported to enable authentication and use across all other scripts. 

// firebase resources
import { firebaseConfig } from "./config";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// initialize firebase and resources
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);


