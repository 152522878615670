import React from 'react'
import './Bale.scss'
import { Modal, Button } from 'react-bootstrap';

import MaterialDropdown from '../components/MaterialDropdown/MaterialDropdown';
import PropertyField from '../components/PropertyField/PropertyField';

import { useNavigate} from 'react-router-dom';
import { MaterialsContext } from '../context/MaterialsContext';
import { getDoc, doc } from "firebase/firestore";
import { firestore } from "../context/firebase";
import { fetchImageUrl } from "../utils/ImgUtils.js"

// Importing icons
import back_icon from '../assets/icons/white/back_small.png';
import weight_icon from '../assets/icons/white/weight.png';
import date_icon from '../assets/icons/white/calendar.png';
import quality_grade_icon from '../assets/icons/white/checkmark.png';
import material_grade_icon from '../assets/icons/white/piechart.png';
import color_icon from '../assets/icons/white/color.png';
import location_icon from '../assets/icons/white/location.png';
import type_icon from '../assets/icons/white/shape.png';
import user_icon from '../assets/icons/white/user.png';
import condition_icon from '../assets/icons/white/clean.png';
import detail_icon from '../assets/icons/white/detail.png';
import alert_icon from '../assets/icons/red/alert.png';
import flag_checked_icon from '../assets/icons/red/flag.png';
import flag_unchecked_icon from '../assets/icons/white/flag.png';

export default function Bale(props) {
    const { labelsets } = React.useContext(MaterialsContext);

    const [baleSubmitter, setBaleSubmitter] = React.useState("");
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);
    const [baleImg, setBaleImg] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (!props.bale) {
            navigate("/deliveries");
        }
    }, []);

    async function getUser(uid) {
        /** Get user based on the uid */
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return;
        }
        return userDocSnap.data();
    }

    async function getBaleSubmitter(uid) {
        /** Get the user who submitted the bale and set his display name */
        const bale_user = await getUser(uid);
        setBaleSubmitter(bale_user.displayName);
    }

    React.useEffect(() => {
        if (!props.bale) {
            return;
        }
        getBaleSubmitter(props.bale.submitted_by);
    }, []);

    React.useEffect(() => {
        if (!props.bale) {
            return;
        }

        setIsLoaded(false);
        fetchImageUrl(props.bale.image_URL).then((url) => {
            setBaleImg( url );
        })
    }, [props?.bale?.image_URL]);

    if (!props.bale) {
        return null;
    }

    return (
        <div className="bale">
            <div className="bale--header">
                <div onClick={props.returnToDelivery} className="bale--back">
                    <img src={back_icon} alt="Back" className="bale--back-icon"/>
                </div>
                <button onClick={() => setShowDeletePopup(true)} className="bale--delete">Löschen</button>
            </div>
            <div className="bale--img-container">
                <img className="bale--img" 
                    src={baleImg} 
                    onLoad={() => setIsLoaded(true)}
                    style={{ display: isLoaded ? 'block' : 'none' }}
                />
                { (!props.isLabelEqual("materials", props.bale.labels.materials) || !props.isLabelEqual("quality", props.bale.labels.quality)) && 
                <img className="bale--alert-icon" src={alert_icon} /> }
                <img className="bale--flag-icon" 
                    src={ props.bale.flagged ? flag_checked_icon : flag_unchecked_icon }
                    onClick={() => props.handleFieldChange({target: {name: "flagged", value: !props.bale.flagged}})}
                />
            </div>
            {/** Add a property field for every labelset with 'enable_bale_app' set to true */}
            <div className="bale--properties">
                <MaterialDropdown 
                    name = "materials"
                    value = {props.bale.labels.materials}
                    handleChange={props.handleLabelChange}
                    highlight = {!props.isLabelEqual("materials", props.bale.labels.materials)}
                />
                { Object.keys(labelsets).map(labelsetId => {
                    if (labelsets[labelsetId]?.enable_bale_app) {
                        const options = {};
                        Object.keys(labelsets[labelsetId].labels).forEach((labelId) => {
                            options[labelId] = labelsets[labelsetId].labels[labelId].display_name;
                        })
                        const icon = labelsetId === "quality" ? quality_grade_icon : detail_icon;
                        const highlight = !props.isLabelEqual(labelsetId, props.bale.labels[labelsetId])
                        let value = props.bale?.labels?.[labelsetId]
                        if (!value) {
                            value = labelsets[labelsetId]?.type === "multiple-choice" ? [] : ""; 
                        }
                        const type = labelsets[labelsetId]?.type==="multiple-choice" ? "multiple-choice-select" : "select";
                        return (
                            <PropertyField 
                                icon={icon} 
                                title={labelsets[labelsetId].name.toUpperCase()}
                                value={value} 
                                name={labelsetId}
                                type={type}
                                handleChange={props.handleLabelChange}
                                editable={true}
                                options={options}
                                highlight = {highlight}
                                key = {labelsetId}
                            />
                        )
                    }
                })}
                <PropertyField 
                    icon={weight_icon} 
                    title="GEWICHT KG"
                    value={props.bale.weight} 
                    name="weight"
                    type="number"
                    handleChange={props.handleFieldChange}
                    editable={true}
                />
                <PropertyField 
                    icon={date_icon} 
                    title="DATUM" 
                    value={props.bale.date} 
                    name="date"
                    type="date"
                    handleChange={props.handleFieldChange}
                    editable={true}
                />
                <PropertyField 
                    icon={location_icon} 
                    title="STANDORT" 
                    value={props.bale.location}
                    name="location"
                    type="text"
                    handleChange={props.handleFieldChange}
                    editable={true}
                />
                <PropertyField 
                    icon={user_icon} 
                    title="EINGEREICHT VON" 
                    value={baleSubmitter} 
                />
            </div>

            <Modal 
                show={showDeletePopup}
                onHide={() => setShowDeletePopup(false)}
                backdrop = "static"
                className = "delete-modal">
                <Modal.Header>
                    <Modal.Title>Bist Du sicher, dass Du diesen Ballen löschen möchtest?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeletePopup(false)}>
                        Abbrechen
                    </Button>
                    <Button variant="danger" onClick={() => {
                            props.deleteBale(props.bale.id); 
                            setShowDeletePopup(false)
                        }}>
                        Löschen
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}