import React from "react";

import { getDoc, getDocs, doc, collection } from "firebase/firestore";
import { firestore } from "../context/firebase";
import { AuthContext } from '../context/AuthContext';

export const MaterialsContext = React.createContext();

export const MaterialsContextProvider = ({children}) => {
    const {currentUser} = React.useContext(AuthContext);
    const [labelsets, setLabelsets] = React.useState({});

    async function getUserCompany(uid) {
        /** Get the company the user belongs to */
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return "";
        }
        return userDocSnap.data().company;
    }

    async function getLabelsets() {
        if (!currentUser.uid) {
            return;
        }
        const company = await getUserCompany(currentUser.uid);

        const labelsetsNew = {};
        const labelsetsSnapshot = await getDocs(collection(firestore, "clients", company, "labelsets"));
        labelsetsSnapshot.forEach((doc) => {
            labelsetsNew[doc.id] = {...doc.data()};
        });
        setLabelsets(labelsetsNew);
    }

    React.useEffect(()=>{
        getLabelsets();
    }, [currentUser]);

    return (
        <MaterialsContext.Provider value={{labelsets}}>
            {children}
        </MaterialsContext.Provider>
    )
};