import React from 'react'
import './Delivery.scss'
import { useLocation } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { format } from 'date-fns';

import PropertyField from '../components/PropertyField/PropertyField';
import BaleCard from '../components/BaleCard/BaleCard';
import BaleScanner from '../components/BaleScanner/BaleScanner';
import Bale from './Bale';
import MaterialDropdown from '../components/MaterialDropdown/MaterialDropdown';
import LoadingScreen from './LoadingScreen'
import { fetchImageUrl, fetchImageThumbnailUrl } from '../utils/ImgUtils'

import { AuthContext } from '../context/AuthContext';
import { MaterialsContext } from '../context/MaterialsContext';

import { onSnapshot, getDoc, setDoc, addDoc, deleteDoc, doc, Timestamp, updateDoc, query, where, collection, arrayUnion, arrayRemove } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { firestore, storage } from "../context/firebase";

// Importing icons
import back_icon from '../assets/icons/white/back_small.png';
import supplier_icon from '../assets/icons/white/delivery.png';
import weight_icon from '../assets/icons/white/weight.png';
import date_icon from '../assets/icons/white/calendar.png';
import quality_grade_icon from '../assets/icons/white/checkmark.png';
import num_bales_icon from '../assets/icons/white/bales_stacked.png';
import material_grade_icon from '../assets/icons/white/piechart.png';
import notes_icon from '../assets/icons/white/document.png';

export default function Delivery() {
    const location = useLocation();
    const delivery_id = location.state.delivery_id;
    const mode = location.state.mode;

    const navigate = useNavigate();

    const { currentUser } = React.useContext(AuthContext);
    const { labelsets } = React.useContext(MaterialsContext);
    const [deliveryInfo, setDeliveryInfo] = React.useState({});
    const [userCompany, setUserCompany] = React.useState("");
    const [bales, setBales] = React.useState({});
    const [activeBaleId, setActiveBaleId] = React.useState("");
    const [notificationEmails, setNotificationEmails] = React.useState([]);
    const [showDeletePopup, setShowDeletePopup] = React.useState(false);
    const [showEmailPopup, setShowEmailPopup] = React.useState(false);
    const [showLoadingScreen, setShowLoadingScreen] = React.useState(false);
    const unsubscribeBales = React.useRef(null);

    async function getUserCompany(uid) {
        /** Get the company the user belongs to */
        const userDocSnap = await getDoc(doc(firestore, "users", uid));
        if (!userDocSnap.exists()) {
            return "";
        }
        return userDocSnap.data().company;
    }

    async function subscribeToBales(company, bale_ids) {
        /** Get all the bales from the delivery */
        if (unsubscribeBales.current) unsubscribeBales.current();

        if (bale_ids.length === 0) {
            return;
        }

        const balesQuery = query(collection(firestore, "clients", company, "bales"), where("id", "in", bale_ids));
        const newUnsubscribe = onSnapshot(balesQuery, (querySnapshot) => {
            const balesNew = {};
            querySnapshot.forEach((doc) => {
                balesNew[doc.id] = {...doc.data(), date: doc.data().date.toDate().toISOString().split('T')[0]};
            });
            setBales(balesNew);
        });
        unsubscribeBales.current = () => newUnsubscribe();
    }

    async function getDeliveryInfo(uid) {
        /** Get all the deliveries from the user's company */
        const company = await getUserCompany(uid);
        setUserCompany(company);
        const deliveryInfoDoc = await getDoc(doc(firestore, "clients", company, "deliveries", delivery_id));
        if (!deliveryInfoDoc.exists()) {
            return {};
        }

        setDeliveryInfo( {...deliveryInfoDoc.data(), date: deliveryInfoDoc.data().date.toDate().toISOString().split('T')[0]});
        subscribeToBales(company, deliveryInfoDoc.data().bales);
    }

    React.useEffect(() => {
        if (currentUser && currentUser.uid) {
            getDeliveryInfo(currentUser.uid);
        }
    }, [ currentUser ]);

    async function getNotificationEmails(company) {
        /** Get emails for discrepancy notifications */
        const companyDocSnap = await getDoc(doc(firestore, "clients", company));
        if (!companyDocSnap.exists() || !('discrepancy_notification_emails' in companyDocSnap.data())) {
            setNotificationEmails([]);
        }

        setNotificationEmails(companyDocSnap.data().discrepancy_notification_emails);
    }
    React.useEffect(() => {
        if (userCompany) {
            getNotificationEmails(userCompany);
        }
    }, [userCompany])

    async function addBale(bale_img) {
        /** Add a new bale to the delivery */
        // Create the entry for the new bale. 
        // While the image is not uploaded, use the image data itself to display it.
        const bale_id = `${currentUser.uid}${new Date().getTime()}`;
        const new_bale = {
            id: bale_id,
            submitted_by: currentUser.uid,
            weight: 235,
            inherit_delivery_props: true,
            delivery: delivery_id,
            image_URL: bale_img,
            date: new Date().toISOString().split('T')[0],
            location: "LKW",
            status: "uploading",
            labels: {
                materials: deliveryInfo.base_labels.materials,
                quality: deliveryInfo.base_labels.quality,
                photo_type: "single_bale",
            },
        }
        setBales(prevBales => ({...prevBales, [bale_id]:new_bale}));
        setActiveBaleId(bale_id);

        // Upload the image to storage and retrieve its URL
        const image_URL = await uploadBale(new_bale);

        // Now that the image is uploaded, update the image entry with the URL of the uploaded image.
        new_bale.status = "uploaded";
        new_bale.image_URL = image_URL;
        setBales(prevBales => ({...prevBales, [bale_id]:new_bale}));

        // Add the bale to the delivery
        uploadFields({
            bales: arrayUnion(new_bale.id),
            preview_img: deliveryInfo.preview_img === "" ? image_URL : deliveryInfo.preview_img,
        });
        setDeliveryInfo(prevDeliveryInfo => ({
            ...prevDeliveryInfo,
            bales: [...prevDeliveryInfo.bales, new_bale.id],
            preview_img: prevDeliveryInfo.preview_img === "" ? image_URL : prevDeliveryInfo.preview_img,
        }));
    }

    function base64ToBlob(base64) {
        /** Convert base64 image to blob */
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/jpeg' });
    };

    async function uploadBale(bale) {
        return new Promise(async (resolve, reject) => {
            const imageDataBlob = base64ToBlob(bale.image_URL);

            // create unique name for each image (user uid + timestamp, for STORAGE)
            const date = new Date().getTime();
            const storageRef = ref(storage, `clients/${userCompany}/delivery_bales/${format(new Date(), "yyyy-MM-dd")}/${date}.jpg`);
        
            await uploadBytesResumable(storageRef, imageDataBlob)
            const downloadURL = await getDownloadURL(storageRef)
            try {
                // Create bale document in firebase
                const balesRef = doc(firestore, "clients", userCompany, "bales", bale.id);
                await setDoc(balesRef, {
                    ... bale, 
                    image_URL: downloadURL, 
                    status: "uploaded",
                    date: Timestamp.fromDate(new Date(bale.date))
                });

                resolve(downloadURL);
            } catch(err) {
                console.log(err);
            }
        });
    }

    async function deleteBale(bale_id) {
        const image_URL = await fetchImageUrl(bales[bale_id].image_URL);
        const image_thumb_URL = await fetchImageThumbnailUrl(bales[bale_id].image_URL);
        /** Navigate back to delivery page */
        navigate(-1);

        /** Delete bale from database */
        await deleteDoc(doc(firestore, "clients", userCompany, "bales", bale_id));

        /** Delete bale from delivery */
        uploadFields({
            bales: arrayRemove(bale_id),
        })
        setDeliveryInfo(prevDeliveryInfo => ({
            ...prevDeliveryInfo,
            bales: prevDeliveryInfo.bales.filter(bale => bale !== bale_id),
        }));

        /** Delete bale from state */
        setBales(prevBales => {
            const newBales = {...prevBales};
            delete newBales[bale_id];
            return newBales;
        });

        /** Delete bale image and thumbnail from storage */
        await deleteObject(ref(storage, image_URL));
        await deleteObject(ref(storage, image_thumb_URL));
    }

    async function deleteDelivery() {
        /** Delete all bales from delivery */
        setShowLoadingScreen(true);

        for (const bale_id of deliveryInfo.bales) {
            await deleteDoc(doc(firestore, "clients", userCompany, "bales", bale_id));
            const image_URL = await fetchImageUrl(bales[bale_id].image_URL);
            const image_thumb_URL = await fetchImageThumbnailUrl(bales[bale_id].image_URL);
            await deleteObject(ref(storage, image_URL));
            await deleteObject(ref(storage, image_thumb_URL));
        }

        /** Delete delivery from database */
        await deleteDoc(doc(firestore, "clients", userCompany, "deliveries", delivery_id));

        /** Navigate back to deliveries page */
        navigate(-1);
    }

    function isLabelEqual(labelsetId, label) {
        /** Check if the label of a bale corresponds with the label of the delivery */
        return true;
        //return deliveryInfo.base_labels[labelsetId] === label;
    }

    function handleDeliveryLabelChange(event) {
        /* Change the labels of a delivery on user input */
        const { name, value } = event.target;        
        setDeliveryInfo(prevDeliveryInfo => ({...prevDeliveryInfo, base_labels:{...prevDeliveryInfo.base_labels, [name]: value}}));

        const uploadKey = `base_labels.${name}`;
        uploadFields({[uploadKey]: value});
    }
    function handleDeliveryFieldChange(event) {
        /* Change properties of a delivery on change */
        const { name, value } = event.target;

        // Update state
        setDeliveryInfo(prevDeliveryInfo => ({...prevDeliveryInfo, [name]: value}));

        // Write new data to firebase
        if (name === "supplier") {
            uploadSupplierField(value, userCompany);
        } else if (name === "notes") {
            uploadNotesField(value, userCompany);
        } else {
            uploadFields({[name]: value});
        }
    }
    function uploadFields(fields_data) {
        // Update a field on firebase
        const deliveryRef = doc(firestore, "clients", userCompany, "deliveries", delivery_id);
        updateDoc(deliveryRef, fields_data);
    }
    const uploadNotesField = React.useCallback(debounce((value, company) => {
        // Update notes with a debouncing timer of 1second.
        const deliveryRef = doc(firestore, "clients", company, "deliveries", delivery_id);
        updateDoc(deliveryRef, {"notes": value});
    }, 1000), []);
    const uploadSupplierField = React.useCallback(debounce((value, company) => {
        // Update supplier with a debouncing timer of 1second.
        const deliveryRef = doc(firestore, "clients", company, "deliveries", delivery_id);
        updateDoc(deliveryRef, {"supplier": value});
    }, 1000), []);

    function handleBaleFieldChange(event) {
        /* Change the properties of a bale on change (labels are handled separately) */
        const { name, value } = event.target;
        const changes = {[name]: value};
        
        // Update state
        setBales(prevBales => ({...prevBales, [activeBaleId]: {...prevBales[activeBaleId], ...changes}}));
    
        // Save changes to database
        const balesRef = doc(firestore, "clients", userCompany, "bales", activeBaleId);
        updateDoc(balesRef, changes);
    }

    function handleBaleLabelChange(event) {
        /* Change the labels of a bale on user input */
        const { name, value } = event.target;

        // Update state. Stop inheriting from delivery once labels have manually changed
        setBales(prevBales => ({
            ...prevBales, 
            [activeBaleId]: {
                ...prevBales[activeBaleId], 
                "inherit_delivery_props": false,
                "labels": {
                    ...prevBales[activeBaleId].labels,
                    [name]: value,
                }
            }
        }));

        // Save changes to database
        const balesRef = doc(firestore, "clients", userCompany, "bales", activeBaleId);
        updateDoc(balesRef, {
            ["inherit_delivery_props"]: false, 
            [`labels.${name}`]: value, 
        });

        // Check for new discrepancies
        if (!isLabelEqual(name, value) && notificationEmails?.length>0) {
            setShowEmailPopup(true);
        }
    }

    function sendDiscrepancyEmail() {
        /* Trigger an email to recipients of the notification emails for bale-delivery discrepancies. Will be processed by firebase add-on. */
        setShowEmailPopup(false);
        for (const email of notificationEmails) {
            addDoc(collection(firestore, "messages"), {
                type: "delivery_discrepancy",
                date: new Date(),
                to: email,
                message: {
                    subject: "[Upcircle Lieferungen] Neue Unstimmigkeit erfasst",
                    html: `Es wurde eine neue Unstimmigkeit für die Lieferung ${deliveryInfo.id} erfasst!<br>
                    Klicke auf den Link, um die Lieferung direkt im Upcircle Dashboard zu öffnen:<br>
                    <a href="https://analytics.upcircle.ai/deliveries/${delivery_id}">Öffne das Upcircle Dashboard</a><br><br>
                    Mit freundlichen Grüssen<br>
                    Dein Upcircle Team`
                }
            })
        }
    }

    async function returnToDeliveriesOverview() {
        // If there are no bales in the delivery, delete the delivery
        if (deliveryInfo.bales.length === 0) {
            await deleteDelivery();
            return;
        }

        // Navigate back to deliveries page
        navigate(-1);
    }

    if (showLoadingScreen || !(deliveryInfo && deliveryInfo.base_labels && labelsets) ) {
        return <LoadingScreen />
    } else if (mode === 'scanning') {
        /* Show the bale-scanner page */
        return (
            <div className="delivery">
                <div className="delivery--header">
                    <div onClick={() => navigate(-1)} className="delivery--back">
                        <img src={back_icon} alt="Back" className="delivery--back-icon"/>
                    </div>
                    <h3 className="delivery--title">Lieferung ID #{delivery_id}</h3>
                </div>

                <BaleScanner
                    addBale={addBale}
                    viewLastBale={() => navigate(`/delivery`, {state: {delivery_id: delivery_id, mode: "bale-overview"}})}
                />
            </div>
        )
    } else if (mode === 'bale-overview') {
        /* Show the bale-overview page */
        return (
            <>
                <Bale 
                    bale={bales[activeBaleId]}
                    deleteBale={deleteBale}
                    handleFieldChange={handleBaleFieldChange}
                    handleLabelChange={handleBaleLabelChange}
                    isLabelEqual={isLabelEqual}
                    returnToDelivery={() => {navigate(-1);}}
                />
                <Modal 
                    show={showEmailPopup}
                    onHide={() => setShowEmailPopup(false)}
                    backdrop = "static"
                    className = "email-modal">
                    <Modal.Header>
                        <Modal.Title>Unstimmigkeit erfasst</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Möchten Sie ein Benachrichtigungs Email senden?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowEmailPopup(false)}>
                            Abbrechen
                        </Button>
                        <Button variant="danger" onClick={sendDiscrepancyEmail}>
                            Senden
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else {
        /* Show the delivery-overview page */
        return (
            <div className="delivery">
                <div className="delivery--header">
                    <div onClick={returnToDeliveriesOverview} className="delivery--back">
                        <img src={back_icon} alt="Back" className="delivery--back-icon"/>
                    </div>
                    <h3 className="delivery--title">Lieferung #{delivery_id}</h3>
                    <button onClick={() => setShowDeletePopup(true)} className="delivery--delete">Löschen</button>
                </div>

                <div className="delivery--content">
                    <MaterialDropdown
                        name = "materials"
                        value = {deliveryInfo.base_labels.materials}
                        handleChange={handleDeliveryLabelChange}
                    />
                    
                    <div className="delivery--properties">
                        <PropertyField 
                            icon={supplier_icon} 
                            title="LIEFERANT" 
                            value={deliveryInfo.supplier} 
                            name={"supplier"} 
                            type="text"
                            handleChange={handleDeliveryFieldChange} 
                            editable={true}
                            grid_span={2}
                        />
                        <PropertyField 
                            icon={weight_icon} 
                            title="GEWICHT KG" 
                            value={deliveryInfo.total_weight_kg} 
                            name="total_weight_kg"
                            type="number"
                            handleChange={handleDeliveryFieldChange}
                            editable={true}
                        />
                        <PropertyField 
                            icon={date_icon} 
                            title="DATUM" 
                            value={deliveryInfo.date} 
                            name="date"
                            type="date"
                            handleChange={handleDeliveryFieldChange}
                            editable={true}
                        />
                        <PropertyField 
                            icon={num_bales_icon} 
                            title="ANZ. BALLEN" 
                            value={deliveryInfo.n_bales} 
                            name="n_bales"
                            type="number"
                            handleChange={handleDeliveryFieldChange}
                            editable={true}
                        />
                        {labelsets?.quality?.enable_bale_app && <PropertyField 
                            icon={quality_grade_icon} 
                            title="QUALITÄT" 
                            value={ deliveryInfo.base_labels.quality } 
                            name="quality"
                            type="select"
                            handleChange={handleDeliveryLabelChange}
                            editable={true}
                            options={Object.keys(labelsets.quality.labels).reduce((acc, key) => {
                                acc[key] = labelsets.quality.labels[key].display_name; 
                                return acc;
                            }, {})}
                        />}
                        <PropertyField 
                            icon={notes_icon} 
                            title="NOTIZEN" 
                            value={ deliveryInfo.notes } 
                            name="notes"
                            type="textarea"
                            handleChange={handleDeliveryFieldChange}
                            editable={true}
                            grid_span={2}
                        />
                    </div>

                    <h3 className="delivery--bales-title">Registrierte Ballen</h3>
                    <div className="delivery--bales">
                        <div className="delivery--bales-card-wrapper">
                            {Object.entries(bales).map(([bale_id, bale]) => {
                                return (
                                    <div onClick={() => { setActiveBaleId(bale_id); navigate(`/delivery`, {state: {delivery_id: delivery_id, mode: "bale-overview"}})}} key={bale_id}>
                                        <BaleCard key={bale_id} 
                                            bale={bale} 
                                            isLabelEqual={isLabelEqual}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                <div className="delivery--button-wrapper">
                    <button className="delivery--add-button" onClick={() => navigate(`/delivery`, {state: {delivery_id: delivery_id, mode: "scanning"}})}>Neuer Ballen</button>
                </div>

                <Modal 
                    show={showDeletePopup}
                    onHide={() => setShowDeletePopup(false)}
                    backdrop = "static"
                    className = "delete-modal">
                    <Modal.Header>
                        <Modal.Title>Bist Du sicher, dass Du die Lieferung löschen möchtest?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeletePopup(false)}>
                            Abbrechen
                        </Button>
                        <Button variant="danger" onClick={deleteDelivery}>
                            Löschen
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}