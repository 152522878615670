import React from 'react'
import "./BaleCard.scss"
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'

import upload_icon from '../../assets/icons/white/upload.png';
import checkmark_icon from '../../assets/icons/white/checkmark.png';
import flag_icon from '../../assets/icons/red/flag.png';
import alert_icon from '../../assets/icons/red/alert.png';

export default function BaleCard(props) {
    const [baleImg, setBaleImg] = React.useState("");

    React.useEffect(() => {
        if (props.bale.image_URL.startsWith("data:image")) {
            // This means it is not actually an URL but the image data itself
            setBaleImg(props.bale.image_URL);
        } else {
            fetchImageThumbnailUrl(props.bale.image_URL).then((url) => {
                setBaleImg(url);
            })
        }
    }, [props.bale.image_URL]);

    return (
        <div className="bale_card">
            { baleImg && <img className="bale_card--img" src={baleImg}/> }
            { props.bale.status && <img className="bale_card--status" 
                src= { props.bale.status === 'uploading' ? upload_icon : checkmark_icon }
            /> }
            { (!props.isLabelEqual(props.bale.labels.materials) || !props.isLabelEqual(props.bale.labels.quality)) &&
                <img className="bale_card--alert" src={alert_icon}/>}
            { props.bale.flagged && <img className="bale_card--flag" 
                src= {flag_icon}
            /> }
        </div>
    )
}