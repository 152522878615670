import React from 'react'
import './HomeButton.scss'
import { Link } from 'react-router-dom';

export default function HomeButton(props) {
    return (
        <Link to={props.link ? props.link : ""} className="home_button">
            {props.coming_soon ? <div className="coming_soon">Coming Soon!</div> : ""}
            <img src={props.icon}/>
            <p>{props.name}</p>
        </Link>
    )
}