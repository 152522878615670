import React from 'react'
import "./Home.scss"
import Navbar from '../components/Navbar/Navbar'
import HomeButton from "../components/HomeButton/HomeButton"

// Importing icons
import delivery_icon from '../assets/icons/white/delivery.png';
import quick_scan_icon from '../assets/icons/white/quick_scan.png';
import factory_feed_icon from '../assets/icons/white/factory_feed.png';

const Home = () => {
    return (
        <div className="home">
            <Navbar/>
            <div className="home_buttons">
                <HomeButton name="Lieferungen" icon={delivery_icon} link="/deliveries"/>
                <HomeButton name="Verarbeitung" icon={factory_feed_icon} coming_soon={true}/>
                <HomeButton name="Schneller Scan" icon={quick_scan_icon} coming_soon={true}/>
            </div> 
        </div>
    )
}

export default Home