// Credentials and settings

// Firebase config for bale-app project (now hosted on Analytics Dashboard Project)
export const firebaseConfig = {
    apiKey: "AIzaSyC9K39Yu1OqDsaDdC2q8tPqsTTT9-ksa9M",
    authDomain: "upcircleai-dashboard.firebaseapp.com",
    projectId: "upcircleai-dashboard",
    storageBucket: "upcircleai-dashboard.appspot.com",
    messagingSenderId: "951071303444",
    appId: "1:951071303444:web:0f9f5863f17b2797484401",
    measurementId: "G-QZQ868R21T"
};  
