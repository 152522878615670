import React from 'react'
import './PropertyField.scss'

import MultiSelectDropdown from '../MultiSelectDropdown/MultiSelectDropdown';

export default function PropertyField(props) {
    const inputRef = React.useRef(null);

    function handleClick() {
        if (props.editable && inputRef.current) {
            inputRef.current.focus();
        }
    }

    function renderView() {
        return (
            <p className="property--value">{props.value}</p>
        )
    }

    function renderInput() {
        return (
            <input className="property--value"
                type={props.type ? props.type : "text"}
                onChange={props.handleChange}
                name={props.name}
                value={props.value}
                ref={inputRef}
            /> 
        )
    }

    function renderTextArea() {
        return (
            <textarea className="property--value"
                onChange={props.handleChange}
                name={props.name}
                value={props.value}
                ref={inputRef}
            /> 
        )
    }

    function renderSelect() {
        if (Object.keys(props.options).length > 1) {
            return (
                <select className="property--value"
                    onChange={props.handleChange}
                    name={props.name}
                    value={props.value}
                    ref={inputRef}
                >
                    {Object.entries(props.options)
                        .sort((a, b) => a[0].localeCompare(b[0])) // Sort alphabetically
                        .map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        )
                    )}
                </select>
            )
        } else {
            return (
                <p className="property--value">{props.options[props.value]}</p>
            )
        }
    }

    function renderMultipleChoiceSelect() {
        return (
            <MultiSelectDropdown 
                selectedOptions={props.value}
                options={props.options}
                onChange={(e) => {props.handleChange({"target":{"name":props.name, "value":e}})}}
            />
        )
    }

    function renderValueField() {
        switch (props.type) {
            case "textarea":
                return renderTextArea();
            case "text":
                return renderInput();
            case "number":
                return renderInput();
            case "date":
                return renderInput();
            case "select":
                return renderSelect();
            case "multiple-choice-select":
                return renderMultipleChoiceSelect();
            default:
                return renderView();
        }
    }

    return (
        <div className={props.highlight ? "property-field property--highlight" : "property-field"}
            style={{"gridColumn": `span ${props.grid_span ? props.grid_span : 1}`}}
            onClick={handleClick}
        >
            <img className="property--icon" src={props.icon} />
            <p className="property--title">{props.title}</p>
            { props.editable ? renderValueField() : <p className="property--value">{props.value}</p> }
        </div>
    )
}