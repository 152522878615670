import React from 'react'
import './DeliveryCard.scss'
import { Link } from 'react-router-dom';
import { fetchImageThumbnailUrl } from '../../utils/ImgUtils'

import { MaterialsContext } from '../../context/MaterialsContext';

export default function DeliveryCard(props) {
    const [deliveryImg, setDeliveryImg] = React.useState("");
    const [isLoaded, setIsLoaded] = React.useState(false);

    const stateObj = {delivery_id: props.delivery.id, mode: "delivery_overview"}
    const { labelsets } = React.useContext(MaterialsContext);

    const baseMaterial = labelsets['materials'].labels[props.delivery.base_labels.materials]

    React.useEffect(() => {
        fetchImageThumbnailUrl(props.delivery.preview_img).then((url) => {
            setDeliveryImg(url)
        })
    }, [props.delivery.preview_img])

    return (
        <Link to="/delivery" state={stateObj} className="delivery_card">
            <div className="delivery--img-container">
                { deliveryImg && <img className="delivery--preview-img" 
                    src={deliveryImg}
                    onLoad={() => setIsLoaded(true)}
                    style={{ display: isLoaded ? 'block' : 'none' }}
                /> }
                { props.delivery.total_weight_kg && <p className="delivery-card--weight">{`${props.delivery.total_weight_kg} Kg`}</p> }
                <div className="delivery-card--material">
                    <p>{baseMaterial.product_group}</p>
                    <p>{baseMaterial.display_name}</p>
                </div>
            </div>
            <div className="delivery--title-div">
                <p className="delivery--title">{props.delivery.supplier}</p>
                <p className="delivery--date">{props.delivery.date.toDate().toLocaleDateString()}</p>
            </div>
            <p className="delivery--subtitle">Lieferung #{props.delivery.id}</p>
        </Link>
    )
}