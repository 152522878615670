import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../context/firebase";

export async function fetchImageUrl(firebase_url) {
    const baseUrl = firebase_url.split("?")[0];
    const imageRef = ref(storage, baseUrl);
    const url = getDownloadURL(imageRef).catch((error) => {
        return "";
    })
    return url;
}

export async function fetchImageThumbnailUrl(firebase_url) {
    const baseUrl = firebase_url.split("?")[0];

    const fileExtension =
    baseUrl.endsWith(".jpeg") ? ".jpeg" :
    baseUrl.endsWith(".jpg") ? ".jpg" :
    baseUrl.endsWith(".png") ? ".png" : ""

    const thumbnailUrl = baseUrl.replace(fileExtension, "") + "_400x400" + fileExtension;
    const thumbImageRef = ref(storage, thumbnailUrl);
    const url = getDownloadURL(thumbImageRef).catch((error) => {
        return fetchImageUrl(firebase_url);
    })
    return url;
}