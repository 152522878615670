import React from "react"
import "./Deliveries.scss"

import { AuthContext } from '../context/AuthContext';
import {collection, query, getDocs, getDoc, doc, setDoc, Timestamp, limit, orderBy, startAfter} from "firebase/firestore";
import { firestore } from "../context/firebase";
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { MaterialsContext } from "../context/MaterialsContext";

import DeliveryCard from "../components/DeliveryCard/DeliveryCard";
import Navbar from '../components/Navbar/Navbar'

import logo_icon from '../assets/logos/upcircle_logo_circle.png';

export default function Deliveries() {
    const { currentUser } = React.useContext(AuthContext);
    const { labelsets } = React.useContext(MaterialsContext);

    const [deliveries, setDeliveries] = React.useState([]);
    const [userCompany, setUserCompany] = React.useState("");
    const lastSnapshot = React.useRef(null);
    const allLoaded = React.useRef(false);
    const blockLoading = React.useRef(false);

    const LOAD_DOCS_BATCH_SIZE = 10;

    const navigate = useNavigate();

    React.useEffect(() => {
        getDeliveries();
    }, []);

    async function getUserCompany() {
        /** Get the company the user belongs to */
        const userDocSnap = await getDoc(doc(firestore, "users", currentUser.uid));
        if (!userDocSnap.exists()) {
            return "";
        }
        return userDocSnap.data().company;
    }

    async function getDeliveries() {
        /** Get all the deliveries from the user's company */
        if (allLoaded.current || blockLoading.current) {
            return;
        }
        blockLoading.current = true;

        const company = await getUserCompany();
        setUserCompany(company);

        const deliveriesCollection = collection(firestore, "clients", company, "deliveries");
        let deliveriesQuery = query(deliveriesCollection, orderBy("date", "desc"), limit(LOAD_DOCS_BATCH_SIZE));

        // Data pagination: Get the next documents in line after the last one
        if (lastSnapshot.current) {
            deliveriesQuery = query(deliveriesQuery, startAfter(lastSnapshot.current));
        }

        const querySnapshot = await getDocs(deliveriesQuery);
        const newDeliveries = [];
        querySnapshot.forEach((doc) => {
            lastSnapshot.current = doc;
            newDeliveries.push(doc.data());
        })
        setDeliveries(prevDeliveries => {
            return [...prevDeliveries, ...newDeliveries];
        });

        // If no more documents are available, set the allLoaded flag
        if (querySnapshot.empty || querySnapshot.size < LOAD_DOCS_BATCH_SIZE) {
            allLoaded.current = true;
        }

        blockLoading.current = false;
    }

    const handleScroll = debounce((e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 200;
        if (bottom) { 
            getDeliveries();
        }
    }, 200);

    async function createNewDelivery() {
        /** Create a new delivery */
        const deliveryId = String(Math.floor(new Date().getTime()/1000));

        // Get all default labels
        const base_labels = {materials: Object.keys(labelsets['materials'].labels)[0]}
        for (const labelsetId of Object.keys(labelsets)) {
            if (labelsets[labelsetId]?.enable_bale_app && labelsets[labelsetId]?.type !== "multiple-choice") {
                base_labels[labelsetId] = Object.keys(labelsets[labelsetId].labels).sort((a, b) => a.localeCompare(b))[0];
            }
        }

        const deliveries_collection = doc(firestore, "clients", userCompany, "deliveries", deliveryId);
        await setDoc(deliveries_collection, {
            id: deliveryId,
            bales: [],
            date: Timestamp.fromDate(new Date()),
            notes: "",
            preview_img: "",
            supplier: "",
            total_weight_kg: "",
            base_labels: base_labels,
        });

        navigate(`/delivery`, {state: {delivery_id: deliveryId, mode: "delivery_overview"}});
    }

    return (
        <div className="deliveries">
            <Navbar/>
            <div className="deliveries--wrapper">
                <h1 className="deliveries--title">Lieferungen</h1>
                <div className="deliveries--cards" onScroll={handleScroll}>
                    {deliveries.map((delivery) => {
                        return <DeliveryCard key={delivery.id} delivery={delivery} />
                    })}
                    {!allLoaded.current && <img src={logo_icon} className="spinning-logo" />}
                </div>
            
                <div className="deliveries--button-wrapper">
                    <button className="deliveries--new-button" onClick={createNewDelivery}>Neue Lieferung</button>
                </div>
            </div>
        </div>
    )
}